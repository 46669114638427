<template>
  <div class="mx-auto auth-wrapper auth-v1">
    <div class="mx-auto auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">Stories</h2>
        </b-link>

        <b-card-text class="mb-2">
          <h4>
            Pegá el código de seguridad que enviamos a tu correo: <b>{{ userEmail }}</b>
          </h4>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
            <!-- email -->
            <b-form-group label="Código" label-for="code-recovery">
              <validation-provider #default="{ errors }" name="Code" rules="required|integer">
                <b-form-input id="code-recovery" v-model="code" :state="errors.length > 0 ? false : null" name="code-recovery" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="text-center">
              <b v-if="msg" style="color: red"> Codigo incorrecto </b>
            </div>
            <!-- submit button -->
            <b-button variant="primary" block type="submit"> Aceptar </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BCard, BLink, BCardText, BFormGroup, BFormInput, BForm, BButton } from 'bootstrap-vue'
import { required, email, integer } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,

    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      code: '',
      // validation
      required,
      email,
      integer,
      msg: false
    }
  },
  computed: {
    userEmail() {
      return this.$store.state.userStore.user.email
    }
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('userStore/recoveryPassRes', { email: this.userEmail, code: this.code }).then(res => {
            if (res === true) {
              this.$noti.success('Codigo correcto!!')
              this.$router.push('/ResetPassword')
            } else {
              this.msg = true
              this.$noti.error('Codigo incorrecto')
            }
          })
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    !store.state.userStore.user.email ? next({ name: 'login' }) : next()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
